import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Ninos() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container className="fluid">
            <Col md="12">
              <Card style={{marginTop:"10em"}}>
                <CardBody>
                  <center>
                    <iframe title="Informacion Niños" width="50px" height="50px" src="https://www.youtube.com/embed/36STQ_Es40A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </center>        
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Ninos;
