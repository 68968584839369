/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Card,CardBody } from "reactstrap";

// core components

function Logos() {
  return (
    <>
      <Card style={{marginLeft:'40px', marginTop:'15px'}}>
        <b style={{marginLeft:'40px', marginTop:'15px'}}>Entidades, agremiaciones y asociaciones</b>
        <CardBody>
          <div className="row text-center">
            <div className="col-md-2 col-sm-1" 
            style={{marginTop:'15px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="#">
                <img
                  alt="Logo de Ministero de Justicia y Derecho de Colombia"
                  className="log"
                  src={require("assets/img/sections/minJusticia2.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-1">
              <a  target="_blank" rel="noopener noreferrer" href="#">
                <img
                  alt="Logo de Procuraduria General del Estado Civil"
                  className="log"
                  src={require("assets/img/sections/procuraduria.jpg")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-1">
              <a  target="_blank" rel="noopener noreferrer" href="#">
                <img
                  alt="Logo VUR"
                  className="log"
                  src={require("assets/img/sections/vur.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-1">
              <a  target="_blank" rel="noopener noreferrer" href="#">
                <img
                  alt="Logo de SNR y Registro"
                  className='log'
                  src={require("assets/img/sections/SNR.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="#">
                <img
                  alt="Logo de Registraduria Nacional del Estado Civil"
                  className='log'
                  src={require("assets/img/sections/registraduria.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.suin-juriscol.gov.co/">
                <img
                  alt="Logo SUIN Juriscol"
                  className='log'
                  src={require("assets/img/sections/suin.png")}
                ></img>
              </a>
            </div>
          </div >
        </CardBody>
      </Card>
      <br></br>
      <br></br>
      <Card style={{marginLeft:'40px' }}>
        <b style={{marginLeft:'40px'}}>Directorio de agremiaciones, asociaciones y otros grupos de interés</b>
        <CardBody>
          <div className="row text-center">
            <div className="col-md-2 col-sm-2">
              <a  target="_blank" rel="noopener noreferrer" href="https://www.gov.co/">
                <img
                  alt="Logo del Gobierno de Colombia"
                  className="log1"
                  src={require("assets/img/sections/logo-gobierno-colombia.png")}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2" 
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.ucnc.com.co/sitio/">
                <img
                  alt="Logo de la Unión Colegiada del Notariado Colombiano"
                  className='log'
                  src={require("assets/img/sections/UnionColegiala.jpg")}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-1" 
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.uinl.org/america-american-affairs-commission-caam-">
                <img
                  alt="Logo de la Comisión de Asuntos Americanos"
                  className="logos"
                  src={require("assets/img/sections/comision.png")}
                ></img>
              </a>
            </div>
          </div >
        </CardBody>
      </Card>
    </>
  );
}

export default Logos;
