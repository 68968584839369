/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";

import './FooterBlack.css'
// core components

function FooterBlack() {
  return (
    <>
      <footer className="subscribe-line subscribe-line-black" data-background-color="black">
        <Container style={{ width: '100% !important' }} >
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticasDatosPersonales">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticasDerechosAutor">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticasPrivacidadWeb">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <a href="/#contact-us">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Canal de Atencion al Publico</h6>
            </a>
            <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer"}}>Telefono: CEL 317 513 0082</h6>
          </div>
          <div className="copyright position-logo" id="copyright" >
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img style={{ backgroundColor: 'white', borderRadius: '27px' }} src={require("assets/img/LOGO_NOT_10.png")} alt="Logo de la notaría 10 de Bucaramanga" width="110px"></img>
            </NavbarBrand>
          </div>
        </Container>
        <div className="div-sinfony">
          <div className="div-redes">
            <div className="copyright position" id="copyright" >
              <NavbarBrand to="/" tag={Link} id="navbar-brand">
                <img style={{ backgroundColor: 'white', borderRadius: '15px' }} src={require("assets/img/facebook.png")} alt="Logo Facebook" width="40px"></img>
              </NavbarBrand>
              <NavbarBrand to="/" tag={Link} id="navbar-brand">
                <img style={{ backgroundColor: 'white', borderRadius: '15px' }} src={require("assets/img/instagram.jpg")} alt="Logo Instagram" width="40px"></img>
              </NavbarBrand>
              <NavbarBrand to="/" tag={Link} id="navbar-brand">
                <img style={{ backgroundColor: 'white', borderRadius: '15px' }} src={require("assets/img/twitter.png")} alt="Logo Twitter" width="40px"></img>
              </NavbarBrand>
            </div>
          </div>
          <h6>© {new Date().getFullYear()}, Designed by{'\u00A0'}</h6>
          <a
            href="https://www.sinfony.com.co/sinfony/"
            target="_blank"
            rel="noreferrer"
            className="item-footer-color"
          >
          <h6>SINFONY Todos los derechos reservados.</h6>  
          </a>
        </div>
      </footer>
    </>
  );
}

export default FooterBlack;
