import React from "react";

// reactstrap components
import {

  Container

} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";


function TerminosCondiciones() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "10em" }}>
        <div className="section">
          <Container>                     
            <h3 className="title text-center" style={{fontFamily: "'Noto Sans SC', sans-serif"}}>
              Términos y condiciones
            </h3>
            <center className="mt-5">
              <a href={require("assets/pdf/Condiciones de Uso.pdf")} style={{textDecoration: "none !important"}} rel="noopener noreferrer" target="_blank">
                <img
                alt="Imagen con referencia Terminos"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Términos y condiciones</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default TerminosCondiciones;
