import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Otrosin() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container className="fluid">
            <Row>
              <Col md="12">
                <Card style={{marginTop:"8em"}}>
                  <CardBody>
                    <center>
                      <iframe width="560" height="615" src="https://www.youtube.com/embed/V-nb0Y5haos" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </center>
                    <center className="mt-5">
                      <a href={require("assets/pdf/1.8.4-PROTOCOLOS-DE-ATENCION-INCLUSIVA-EN-EL-ACCESO-A-LA-JUSTICIA-PARA-PERSONAS-CON-DISCAPACIDAD-2.pdf")} style={{ textDecoration: "none !important" }} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="Imagen con referencia Protocolo Atencion"
                          width="80px"
                          src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">Protocolo Atencion Inclusiva</h6>
                      </a>
                    </center>
                    <center className="mt-5">
                      <a href={require("assets/pdf/PROTOCOLO DE ATENCIÓN A PERSONAS CON CAPACIDAD REDUCIDA 31-05-2024.pdf")} style={{ textDecoration: "none !important" }} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="Imagen con referencia Protocolo Personas"
                          width="80px"
                          src={require("assets/img/sections/pdf.jpg")}
                        ></img>
                        <h6 className="my-3">Protocolo Personas Capacidad Reducida</h6>
                      </a>
                    </center>      
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Otrosin;
